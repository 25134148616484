<template>
    <div v-if="item && item.avatar" class="d-flex flex-grow-0 align-center grey--text text--darken-3">
      <div class="py-1 py-sm-3 pr-1 pr-sm-2">
        <v-btn
          dense
          depressed
          small
          class="cs-alter-bg white--text px-1 px-sm-3"
        >
          出品人：{{ item.name }}
        </v-btn>
      </div>
    </div>
</template>
<script>
export default {
  name: 'LecturerLeader',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-alter-bg
  background-image: linear-gradient(to right, #016ACB 0%, #01BDCB 100%)
  box-shadow: inset 0 -2px 3px 0 rgba(255,255,255,0.50)
</style>